import React, { useState } from "react";
import Layout from "../../components/Layout";
import Navbar from "../../components/Navbar";
import PageHeader from "../../components/PageHeader";
import Footer from "../../components/Footer";
import image from "../../assets/images/courses/preschool.jpg";
import { Modal, Button } from "react-bootstrap";
import HubspotContactForm from "./hubspot/HubspotContactForm";

import Accordion from "react-bootstrap/Accordion";

const CoursesPage = () => {
  const [showModal, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <Layout pageTitle="KWEC | Course Details">
      <Navbar />
      <PageHeader title="Course Details" />
      <section className="course-details">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="course-details__content">
                <div className="course-details__top">
                  <div className="course-details__top-left">
                    <h2 className="course-details__title">
                      Jolly Phonics & Grammar
                    </h2>
                  </div>
                  <div className="course-details__top-right">
                    <a className="course-one__category">
                      for young learners & Teachers
                    </a>
                  </div>
                </div>
                <div className="course-one__image">
                  <img src={image} alt="" />
                </div>
                <div className="tab-content course-details__tab-content ">
                  <div
                    className="tab-pane show active  animated fadeInUp"
                    role="tabpanel"
                    id="overview"
                  >
                    <p className="course-details__tab-text">
                      {" "}
                      Phonics is the science of sound, the very base on which
                      pronunciation of the words of a language, rests. It is
                      absolutely critical for your child to have a firm grasp on
                      phonics and grammar, to develop superlative linguistic
                      skills. Our courses, based on the principles of the
                      critically acclaimed Jolly Phonics and Jolly Grammar
                      (developed by Jolly Learning, UK), facilitates in
                      achieving that.{" "}
                    </p>
                    <br />
                    Early childhood educational institutions, children of
                    different age-groups and their parents too can benefit from
                    the Jolly Phonics as well as Jolly Grammar courses that we
                    offer.
                    <br />
                    <Accordion defaultActiveKey="0">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header className="accordion-header">
                          For Educational Institutions
                        </Accordion.Header>
                        <Accordion.Body className="accordion-body">
                          The Jolly Phonics course consists of a two-day
                          intensive training programme covering the essentials
                          of Jolly Phonics, theoretical aspects and practical
                          teaching methods. You may choose to augment the
                          programme by opting for the Jolly Grammar course
                          consisting of six comprehensive teachers’ training
                          modules.
                          <br />
                          All these programmes can be conducted for the entire
                          early childhood education staff, in the school
                          premises itself.
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header className="accordion-header">For Children</Accordion.Header>
                        <Accordion.Body className="accordion-body">
                          We conduct interactive Jolly Phonics classroom
                          training for children belonging to the age group of
                          three and a half years to seven years. Hourly sessions
                          will be conducted twice a week from June to February,
                          every year.
                          <br />
                          Jolly Grammar classes are available for school
                          children studying in the first grade to fourth grade.
                          Hourly sessions will be conducted twice a week from
                          June to February, every year.
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="2">
                        <Accordion.Header className="accordion-header">
                          For Parents and Independent Early Childhood Educators
                        </Accordion.Header>
                        <Accordion.Body className="accordion-body">
                          Young parents, independent early childhood educators
                          and generally, anybody who is keen to work with young
                          learners can benefit from our Jolly Phonics and Jolly
                          Grammar courses. We can recommend customized modules
                          to reinforce your phonics and grammar knowledge, to
                          suit your teaching aspirations.
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 ">
              <div className="course-details__price">
                <p className="course-details__price-text">
                  Talk to our executive and enroll now!{" "}
                </p>
                <Button
                  onClick={handleShow}
                  className="thm-btn course-details__price-btn"
                  variant="default"
                >
                  Enquire Now
                </Button>
              </div>
            </div>
            <Modal show={showModal} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Enquiry Form</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <HubspotContactForm />
              </Modal.Body>
            </Modal>
          </div>
        </div>
      </section>
      <Footer />
    </Layout>
  );
};

export default CoursesPage;
